import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'
import SEO from 'components/Seo'
import AppHeading from 'components/AppHeading'
import ExhibitionList from 'components/widgets/Exhibition/ExhibitionList'
import ExhibitionFilter from 'components/widgets/Exhibition/ExhibitionFilter'
import { useReference } from 'store/Reference'
import { useAnalysis } from 'store/Analysis'
import { pageView } from 'utils/tracker'
import { fetchApiAnalysis } from 'utils/apis'
import { TRACKING } from 'config/constants/common'
import { TYPE_OF_LIST } from 'config/constants/panel'
import './index.scss'

export default function Panels(props) {
  const { getMyListFavorite } = useReference()
  const { getListPanelsViews } = useAnalysis()
  const { t } = useTranslation('exhibitions')
  const { location = {}, data = {} } = props || {}
  const { state } = location || {}
  const { filterTagGroup = {} } = state || {}

  const [filter, setFilter] = useState(filterTagGroup)
  const [relevan, setRelevan] = useState('')
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    // Fetch analysis apis
    async function fetchApis() {
      await fetchApiAnalysis(getListPanelsViews)
    }
    const getMyList = async () => {
      await getMyListFavorite()
    }

    fetchApis()
    pageView(trackingEventSource)
    getMyList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { postgres = {} } = data || {}
  const { allEcmsEventTracks = {} } = postgres || {}
  const { nodes: listTracks = [] } = allEcmsEventTracks || {}
  const trackingEventSource = TRACKING.SOURCE.EXHIBITIONS

  return (
    <div id="exhibitions-view" className={'Layout-children'}>
      <SEO title={t('lbl_title_en')} />
      <AppHeading title={t('lbl_title_en')} />

      <div style={{ paddingTop: 12 }}>
        <ExhibitionFilter
          filter={filter}
          setFilter={setFilter}
          relevan={relevan}
          setRelevan={setRelevan}
          selectedRadio={TYPE_OF_LIST.ALL_EXHIBITION}
          expanded={expanded}
          setExpanded={setExpanded}
          haveRadio
          navRadio
        />

        <Container className="listExhibitions">
          <ExhibitionList
            filter={filter}
            isAll
            listTracks={listTracks}
            trackingEventSource={trackingEventSource}
          />
        </Container>
      </div>
    </div>
  )
}

export const query = graphql`
  query($eventId: Int!) {
    postgres {
      allEcmsEventTracks(orderBy: SEQUENCE_NO_ASC, condition: { eventId: $eventId }) {
        nodes {
          ecmsEventTopicsByTrackId(orderBy: SEQUENCE_NO_ASC) {
            nodes {
              ecmsEventPanelsByTopicId(orderBy: SEQUENCE_NO_ASC) {
                nodes {
                  languageCode
                  panelCode
                  panelDescription
                  panelId
                  panelName
                  shortCode
                  sequenceNo
                  ecmsPanelTagsByPanelId {
                    nodes {
                      ecmsTagListByTagListId {
                        tagGroupId
                        tagListId
                      }
                    }
                  }
                  ecmsEventFilesByPanelId {
                    nodes {
                      id
                      eventId
                      fileCategory
                      fileCode
                      fileDescription
                      fileId
                      fileSize
                      fileType
                      fileName
                      keyCode
                      url
                    }
                  }
                }
              }
              topicId
              topicName
              sequenceNo
              languageCode
            }
          }
          trackId
          trackName
          sequenceNo
          languageCode
        }
      }
    }
  }
`
