import React, { useMemo } from 'react'
import { Box, Container } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocation } from '@reach/router'
import { BREADCRUMB_INACTIVE } from 'config/constants'
import styles from 'assets/scss/common/_variable.scss'
import { customTypography } from 'utils/customComponentMaterialUI'
import Breadcrumb from './Breadcrumb'
import './index.scss'

const AppHeading = (props) => {
  const location = useLocation()
  const { t } = useTranslation('home')
  const { title, customClass = '', breadCrumbTitle = '', isFullSize } = props

  const isShowBreadcrumb = useMemo(() => {
    return !BREADCRUMB_INACTIVE.includes(location.pathname)
  }, [location])

  const TitleTypography = customTypography(styles.clWhite)

  return (
    <div className={`AppHeading-area ${customClass}`}>
      <Box component={isFullSize ? Box : Container}>
        <Breadcrumb
          t={t}
          location={location}
          isShowBreadcrumb={isShowBreadcrumb}
          breadCrumbTitle={breadCrumbTitle}
        />
        {title && (
          <TitleTypography variant="h4" key={'titleAppHeading'} className="AppHeading-title">
            {title}
          </TitleTypography>
        )}
      </Box>
    </div>
  )
}

export default AppHeading
