import React from 'react'
import { Link } from 'gatsby'
import { Typography, Breadcrumbs } from '@material-ui/core'
import './index.scss'

const Breadcrumb = (props) => {
  const { t, location, isShowBreadcrumb, breadCrumbTitle } = props
  const pathNames = location.pathname.split('/').filter((p) => p !== '' && p !== t('lbl_home_en'))

  return !isShowBreadcrumb ? (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="›"
      className="breadcrumb-header"
      key={'breadcrumbs'}
    >
      <p>&nbsp;</p>
    </Breadcrumbs>
  ) : (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="›"
      className="breadcrumb-header"
      key={'breadcrumbs'}
    >
      <Link to="/">
        <Typography component={'p'} style={{ fontSize: '0.8rem' }}>
          {t('lbl_home_en')}
        </Typography>
      </Link>

      {pathNames.map((path, index) => {
        const isLast = index + 1 === pathNames.length

        if (isLast) {
          return (
            <Typography
              component={!isLast ? Link : 'p'}
              style={{ fontSize: '0.8rem' }}
              className="link-latest"
              key={path}
            >
              {breadCrumbTitle ? t(breadCrumbTitle) : t(`${path}_en`)}
            </Typography>
          )
        }

        return (
          <Link to={`/${path}`} key={'lastBreadcrumb'}>
            <Typography component={'p'} style={{ fontSize: '0.8rem' }}>
              {t(`${path}_en`)}
            </Typography>
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default Breadcrumb
